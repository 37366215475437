import { useRef } from "react"
import apiCall, { URLS } from "../../webservice/ApiCallService";
import toast from "react-hot-toast";
export default function ForgetPop({show,handlePopClose})
{
  const mailRef = useRef()

  const send = async (e)=>{
    e.preventDefault();
    handlePopClose(false);
    toast.promise(apiCall.postCall(URLS.RESET_PASSWORD,{email : mailRef.current.value}),{
      loading : "Sending Email ..",
      success : (result)=>{
        if(result.status)
          toast.success(result.msg);
        else  
          toast.error(result.msg);
      }
    });
  }

  return <div
    className={`modal fade ${show ? "show d-block" : ""}`}
    tabIndex="-1"
    role="dialog"
    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-md">FORGOT PASSWORD</h5>
          <button type="button" className="close" aria-label="Close">
            <span aria-hidden="true" onClick={handlePopClose}>&times;</span>
          </button>
        </div>
        <div className="modal-body p-4">
         <div className="row">
            <div className="col-xl-12 col-lg-12">
              <form onSubmit={send}>
                <label htmlFor="email" className="lbl">Email</label>
                <input className="form-control" id="email" ref={mailRef} type="email" required placeholder="Enter Email"/>
                <br/>
                <span>
                <button className="bg-success p-2 rounded btn btn-sm text-white mt-2" style={{letterSpacing:"3px"}}>SEND</button></span>
                </form>
            </div>
         </div>
        </div>
      </div>
    </div>
  </div>
}