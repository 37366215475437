import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function StrategyList() 
{
  const navigate = useNavigate();
  const [editID, setEditID] = useState(undefined);
  const [showPop, setShowPop] = useState(false);
  const [status,setStatus] = useState(true);

  const handleOpenPop = (id) => {
    setShowPop(true);
    setEditID(id);
  };
  const handleClosePop = () => setShowPop(false);
  const [strategyList, setStrategyList] = useState([]);
  const token = useSelector((store) => store.user.value.token);
  useEffect(() => {
    loadStrategy();
  }, [strategyList]);
  const loadStrategy = async () => {
    const resp = await apiCall.getCallWithToken(URLS.LIST_STRATEGY, token);
    setStrategyList(
      resp.data.map((ob, ind) => {
        return { ...ob, index: ind + 1 };
      })
    );
  };
  const changeStatus = (userId) => {
    apiCall.patchCallWithToken(
      URLS.CHANGE_STATUS_STRATEGY + `${userId}`,
      token
    );
    loadStrategy();
  };
  
  const isTimeBetween = ()=> 
  {
    const [startHour, startMinute, endHour, endMinute] = [9,0,15,30];
    const now = new Date();
    const currentTime = now.getHours() * 60 + now.getMinutes(); // Convert current time to minutes

    const startTime = startHour * 60 + startMinute; // Convert start time to minutes
    const endTime = endHour * 60 + endMinute; // Convert end time to minutes

    return currentTime >= startTime && currentTime <= endTime;
  }
  const marketRunning = false; //isTimeBetween()
  return (
    <>
      <div className="container-fluid pt-3">
        <h3 id="HeadText">
          List Strategies
        </h3>

        <div className="text-right">
          <button onClick={()=>setStatus(true)} className="btn btn-success">Active</button>
          &nbsp;&nbsp;
          <button onClick={()=>setStatus(false)} className="btn btn-success">DeActive</button>
        </div>

        <table className="table">
          <thead className="text-danger">
            <tr className="text-center">
              <th>S. No. </th>
              <th>Strategy Name </th>
              <th>Strategy Type </th>
              <th>Strategy Legs </th>
              <th>Actions </th>
            </tr>
          </thead>
          <tbody>
            {strategyList.filter(ob=>ob.status==status).map((ob,i) => {
              let instrument = JSON.parse(ob.instrument);
              let legs = ob.legs
              return (
                <tr key={`strategyList${i}`} className="text-center">
                  <td className="text-dark">{ob.index}</td>
                  <td className="text-dark">
                    <div>
                      <span><b>{ob.name}</b></span>
                      <br />
                      <span>{instrument.name}</span>
                    </div>
                  </td>
                  <td>
                    <b>{ob.type}</b>
                  </td>
                  <td>
                    <b>{legs.length}</b>
                  </td>
                  <td>
                  <div className="d-flex justify-content-evenly">
                    
                    {ob.status?<>
                      <button className="btn btn-sm btn-outline-primary" onClick={()=>navigate(`/admin/strategy/${ob.id}`)} disabled={marketRunning}>Edit</button>
                      &nbsp;
                      <button className="btn btn-sm btn-outline-danger" onClick={()=>changeStatus(ob.id)} disabled={marketRunning}>Deactive</button>
                    </>:<button className="btn btn-sm btn-outline-success" onClick={()=>changeStatus(ob.id)} disabled={marketRunning}>Active</button>}
                  </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
