import toast from "react-hot-toast";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";

export default function EditBrokerForm({ mbrokers , editBroker , setEditBroker , setIsEdit }) 
{  
 
  const [formdata,setFormData] = useState({})
  const token = useSelector((store) => store.user.value.token);
  const mBrokerUI = mbrokers.find(mb=>mb.name==editBroker.name);
  mBrokerUI.uis = JSON.parse(mBrokerUI.uidetails);


  const brokerLoginAndSave = (credentials, event) => 
  {
    toast.promise(
      apiCall.postCallWithToken(URLS.BROKER_LOGIN, token, {formdata : credentials, name : editBroker.name}),
      {
        msg: "Broker Editing ..",
        success: (result) => {
          //console.log(result);
          if (result.status) 
          {
            const URL = URLS.EDIT_BROKER + editBroker.id;
            apiCall
              .postCallWithToken(URL, token, credentials)
              .then((result) => {
                console.log(result)
                if (result.status) {
                  toast.success(result.msg);
                  
                  event.target.reset();
                } else {
                  toast.error("Broker Update Failed !");
                }
              })
              .catch((result) => {
                toast.error("Broker Update Failed !");
              });
          } else {
            toast.error("Broker Connection Failed !");
          }
        },
        error: (result) => {
          //console.log(result);
          toast.error("Broker Connection Failed !");
        },
      }
    );
  };


  const save = (event) => {
    event.preventDefault();
    console.log(formdata)   
    var credentials = {...formdata};
    brokerLoginAndSave(credentials, event);  
  };

  const handleChange = (event)=>{
    const name = event.target.name;
    const value = event.target.value;

    var obj = {};
    obj[name] = value;
    setFormData({ ...formdata, ...obj });
  }

  return (
    <>
        <form onSubmit={save}>
        <div className="row">
          <h4>Edit Broker</h4>
          
        </div>
        {editBroker !== undefined ? (
          <>
            <div className="row mt-3">
             {Object.keys(mBrokerUI.uis)
                .map((key,i) => (
                  <div key={`sbroker${i}`} className="col-xl-3 col-lg-3 p-2">
                    <input
                      className="form-control"
                      type={mBrokerUI.uis[key].type}
                      placeholder={mBrokerUI.uis[key].title}
                      onChange={handleChange}
                      name={key}
                      required
                    />
                  </div>
                ))}
            </div>
            <div className="row mt-3">
              <div className="col-xl-2 col-lg-2">
                <button
                  className="btn btn-success mt-2"
                  disabled={editBroker === undefined}
                >
                  Edit Broker
                </button>
              </div>
              <div className="col-xl-2 col-lg-2">
                <button
                  onClick={() => {
                    setIsEdit(false);
                    setEditBroker(undefined);
                  }}
                  className="btn btn-primary mt-2"
                >
                  Close
                </button>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </form>
    </>
  );
}
