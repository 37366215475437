import { FaPlus } from 'react-icons/fa'
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useEffect, useState } from "react";
import './Broker.css'
import "../custom-client.css";
import AddBrokerForm from './AddBrokerForm'
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {addBrokers,addNewBroker} from '../../../reduxconfig/ClientDataSlice';

import { DataGrid } from '@mui/x-data-grid';
import EditBrokerForm from './EditBrokerForm';
export default function Broker()
{
    const [mbrokers, setMBrokerList] = useState([]);  
    const token = useSelector((store) => store.user.value.token); 
    const brokerlist = useSelector((store)=> store.clientinfo.value.brokers)
    const dispatch = useDispatch()
    const [isAdd,setIsAdd] = useState(false)
   
    const [isEdit,setIsEdit] = useState(false)
    const [editBroker,setEditBroker] = useState(undefined);
    
    useEffect(() =>{ 
      loadMBroker();
    },[]);
    const loadMBroker = () => {
      apiCall.getCall(URLS.LIST_MASTERBROKER).then((res) => {
        if (res.status) {
          setMBrokerList(res.data);
        }
      });
    };
    
    const addBroker = (broker)=>dispatch(addNewBroker(broker));

    const setDefault = (id)=>
    {
        const url = `${URLS.SET_DEFAULT_BROKER}/${id}`
        apiCall.patchCallWithToken(url,token)
        .then(result=>{
            console.log(result)
            if(result.status){
                dispatch(addBrokers(brokerlist.map(b=>{
                    return b.id===id?{...b,isdefault:true}:{...b,isdefault:false}
                })))
                toast.success(result.msg)
            }else{
                toast.error(result.msg)
            }
        })
    }
    const columns = [
        { field: "index", headerName: "S. No.", width: 60 },
        { field: "name", headerName: "Broker Name", width: 300 },
        { field: "appname", headerName: "App Name", width: 300,
            renderCell: (params) => {
                const {details}=params.row;
                return<>
                    {details.appname}
                </>
            }
         },
        {   field: "actions",
            headerName: "Default",
            width: 300,
            renderCell: (params) => {
            const { id, isdefault } = params.row;
            return (
              <>
                {isdefault===false ?(
                  <b
                    data-id="statususer"
                    variant="contained"
                    color={isdefault=== true ? 'secondary' : 'primary'}
                    onClick={(e) =>setDefault(id)}
                    style={{padding:"5px", marginRight: '10px',cursor:"pointer",outline:"1px solid green",borderRadius:"10px" }}
                    >
                    Set Default
                  </b>
                ):
                <b
                  style={{padding:"5px", marginRight: '10px',borderRadius:"10px"}}
                  variant="contained"
                  color="success"
                >
                  Default
                </b>}
              </>
            );
          },
        }
      ];
    return <>
        <div className="container-fluid Broker">
            <div className="row mt-3">
                <div className='col-xl-10 col-lg-10'>
                <h3 id="HeadText">Brokers</h3>
                </div>
                {!isAdd && <div className='col-xl-2 col-lg-2 text-center'>
                    <button onClick={()=>setIsAdd(true)} className='btn btn-success'><FaPlus/>
                    &nbsp;&nbsp;
                    Add</button>
                </div> }
            </div>
            {isAdd?<AddBrokerForm addNewBroker={addBroker} mbrokers={mbrokers} setIsAdd={setIsAdd}/>:""}
            <hr/>      
            {isEdit && editBroker!=undefined?<EditBrokerForm mbrokers={mbrokers} editBroker={editBroker} setEditBroker={setEditBroker} setIsEdit={setIsEdit}/>:""}
            <hr/>
            {/* <div style={{ height: 400, width: "100%" }}>
                <DataGrid rows={brokerlist} columns={columns} pageSize={5}/>
            </div> */}
            <table className='table'>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Broker</th>
                  <th>App Name</th>
                  <th>Default</th>
                  <th>Update</th>
                </tr>
              </thead>
              <tbody>
                {brokerlist.map((bk,index)=><tr key={`bk${index}`}>
                  <td>{index+1}</td>
                  <td>{bk.name}</td>
                  <td>{bk.details.appname}</td>
                  <td>
                  {bk.isdefault===false ?(
                  <b
                    data-id="statususer"
                    variant="contained"
                    color={bk.isdefault=== true ? 'secondary' : 'primary'}
                    onClick={(e) =>setDefault(bk.id)}
                    style={{padding:"5px", marginRight: '10px',cursor:"pointer",outline:"1px solid green",borderRadius:"10px" }}
                    >
                    Set Default
                  </b>
                ):
                <b
                  style={{padding:"5px", marginRight: '10px',borderRadius:"10px"}}
                  variant="contained"
                  color="success"
                >
                  Default
                </b>}
                  </td>
                  <td>
                    <button onClick={()=>{
                      setIsEdit(true);
                      setEditBroker(bk);
                    }} className='btn-sm btn-success'>Edit</button>
                  </td>
                </tr>)}
              </tbody>
            </table>
        </div>
    </> 
}