import React, { useEffect, useState } from 'react';
import './custom-client.css';
import { Settings } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {delUserData} from '../../reduxconfig/UserSlice';
import toast from 'react-hot-toast';

import apiCall , {URLS} from '../../webservice/ApiCallService';

export default function ClientNav() 
{
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginUser = useSelector((store) => store.user.value);

  const [prices,setPrices] = useState({
    Nifty50 : {price:0,diff:0} , BankNifty : {price:0,diff:0} , Sensex : {price:0,diff:0}
  });
  const indexes = {
    "^NSEI" : 'Nifty50', 
    "^NSEBANK" : 'BankNifty',
    "^BSESN" : 'Sensex'
  }
  
  const fetchPrice = async ()=> 
  {
    
  }


  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const result = await apiCall.getCall(URLS.PRICES);
        if (result.status) {
          const data = result.data;
          const priceData = Object.fromEntries(
            data.map(ob => {
              const exchange = indexes[ob.ticker]; // Ensure 'indexes' is defined
              const lastClose = ob.lastClose;
              const price = ob.currentPrice;
              const diff =  price-lastClose;
              return [exchange, {price,diff,lastClose}];
            })
          );
          setPrices(priceData); // Update state with the processed data
        }
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    }, 2000);
  
    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []); // Add 'indexes' if it's a dependency
  
  const logout = ()=>
  {    
      toast.success('Thanks , See You Soon !')
      dispatch(delUserData())
      navigate("/user/login")
  }

  function calculatePercentageChange(currentPrice, lastClosingPrice) {
    let change = ((currentPrice - lastClosingPrice) / lastClosingPrice) * 100;
    return change.toFixed(2) + "%"; // Rounds to 2 decimal places
  }

  return <>
     <nav className="navbar">
      <div className="navbar-brand"></div>
      <div className='h-100 px-2 rounded' style={{background:"linear-gradient(to top, #4ca1af, #c4e0e5) 70%",letterSpacing:"2px",color:'black',fontWeight:700,textShadow:'1px 1px yellow'}}>
        NIFTY 50 : {prices.Nifty50?.price.toFixed(2)}
        <br/>
        {prices.Nifty50?.diff!=0?<div className={prices.Nifty50?.diff<0?'text-center text-danger':'text-center text-success'}>{prices.Nifty50?.diff.toFixed(2)} &nbsp; ({calculatePercentageChange(prices.Nifty50?.price,prices.Nifty50?.lastClose)})</div>:""}
        
      </div>
      <div className='h-100 px-2 rounded' style={{background:"linear-gradient(to top, #4ca1af, #c4e0e5) 70%",letterSpacing:"3px",color:'black',fontWeight:700,textShadow:'1px 1px yellow'}}>
        Bank NIFTY : {prices.BankNifty?.price.toFixed(2)}
        <br/>
        {prices.BankNifty?.diff!=0?<div className={prices.BankNifty?.diff<0?'text-center text-danger':'text-center text-success'}>{prices.BankNifty?.diff.toFixed(2)} &nbsp; ({calculatePercentageChange(prices.BankNifty?.price,prices.BankNifty?.lastClose)})</div>:""}
      </div>
      <div className='h-100 px-2 rounded' style={{background:"linear-gradient(to top, #4ca1af, #c4e0e5) 70%",letterSpacing:"3px",color:'black',fontWeight:700,textShadow:'1px 1px yellow'}}>
        Sensex : {prices.Sensex?.price.toFixed(2)}
        <br/>
        {prices.Sensex?.diff!=0?<div className={prices.Sensex?.diff<0?'text-center text-danger':'text-center text-success'}>{prices.Sensex?.diff.toFixed(2)} &nbsp; ({calculatePercentageChange(prices.Sensex?.price,prices.Sensex?.lastClose)})</div>:""}
      </div>

      {/* <div className="navbar-icons">
        <div className="icon-container profile">
          <Settings/>
          <div className="dropdown-menu">
            <ul>
              <li>My Profile</li>
              <li><Link to="/client/changepassword">Change Password</Link></li>
              <li onClick={logout}>Logout</li>
            </ul>
          </div>
        </div> */}

<div className="navbar-icons">
          <div className="icon-container profile d-flex flex-row-reverse align-items-cenyter justify-content-between gap-1 ">
            <div className="profile-circle text-center align-content-center">
                         <b>{loginUser.name[0].toUpperCase()}</b>
            </div>
            <Settings />
            <div className="dropdown-menu">
              <ul>
                <li>My Profile</li>
                <li>
                  <Link to="/client/changepassword">Change Password</Link>
                </li>
                <li onClick={logout}>Logout</li>
              </ul>
            </div>
          </div>

        {/* Profile with hover dropdown */}
        
      </div>
    </nav>
    <hr/>
  </>
}
