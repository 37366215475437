import { useEffect, useState } from "react";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import { EditSharp } from "@mui/icons-material";
import { FaChevronDown, FaChevronUp, FaTrash } from "react-icons/fa";
import { useRef } from "react";
import toast from "react-hot-toast";
import TimeBasedItem from "./TimeBasedItem";
import IndicatorBasedItem from "./IndicatorBasedItem";

const DeployedItem = ({ obj, token, removeFromList, updateDeployItem }) => 
{
      const [showRun,setShowRun] = useState(true);
      const brokers = useSelector((store) => store.clientinfo.value.brokers);
    
      const [strategy, setStrategy] = useState(undefined);
      const [runningStrategy,setRunningStrategy] = useState([])
      const [show, setShow] = useState(false);

      const [totalPNL,setTotalPNL] = useState({})
    
      const [editPlan,setEditPlan] = useState({
          qtyMultiply: 1,
          squareoff: strategy?.squareoff_time,
          broker: null,
      })

      const placeOrderRef = useRef(undefined);
    
      useEffect(() => {
        loadStrategy();
        loadRunningStrategy();
      }, []);
    
      const loadRunningStrategy = async()=>
      {
        const url = URLS.LIST_RUNNING_STRATEGY + obj._id;
        const res = await apiCall.getCallWithToken(url, token);
        //console.log("RUNNING_STRATEGY : ", res);
        if(res.status)
        {
            setRunningStrategy(res.data);
        }
      }

      const deleteRunningStrategy = (id)=>{       
          setRunningStrategy(runningStrategy.filter(rs=>rs._id!=id))
      }
      const updateRunningStrategy = (newItem)=>{       
        setRunningStrategy(runningStrategy.map(rs=>rs._id===newItem._id?newItem:rs))
      }
    
      const handlePopClose = () => {
        setShow(false);
      };
      const loadStrategy = async () => {
        const url = URLS.GET_ACTIVE_STRATEGY + obj.strategy;
        const res = await apiCall.getCallWithToken(url, token);
        console.log(res);
        if (res.status && res.data!=null) {
          setStrategy(res.data);
          setEditPlan({...editPlan,squareoff : res.data.squareoff_time})
        }
      };
    
      const unDepoly = () => {
        if(runningStrategy.length==0)
        {
          const url = URLS.UNDEPLOY_STRATEGY + obj._id;
          apiCall.patchCallWithToken(url, token).then((res) => {
            if (res.status) {
              removeFromList(obj._id);
            }
          });
        }else
          toast.error("Strategies are running.")
      };
    
      const edit = () => {
        setShow(true);
      };
    
      const startStrategy = async () => {
        //console.log(obj)
        if (editPlan.broker == null || editPlan.broker == undefined) {
            edit();
        } else {
          const brokerDeployStatus = brokers.some(
            (b) => b.name == editPlan.broker && b.isdeployon
          );
          if (brokerDeployStatus) 
          {
            const did = obj._id;
            const URL = URLS.START_STRATEGY + did;
          
            const result = await apiCall.postCallWithToken(URL, token , editPlan);
            if (result.status) 
            {
              const newItem = { ...obj, isrunning: true };
              toast.success(result.msg);
    
              const {diff,currentStartStrategy} = result.data;
              setRunningStrategy([...runningStrategy,currentStartStrategy]);


              placeOrderRef.current = setTimeout((strategy)=>{
                  setRunningStrategy(runningStrategy.map(st=>st._id==strategy._id?{...st,isordered:true}:st))
              },diff,currentStartStrategy);

            } else {
              toast.error(result.msg);
            }
          } else toast.error(`${editPlan.broker} Broker Deploy Off !`);
        }
      };
    
     
    
      const changeStrategyPNL = (id,pnl)=>
      {
          const pnlrec = {...totalPNL};
          pnlrec[id] = pnl;
          setTotalPNL(pnlrec);
      }
      const getTotalPNL = ()=>
      {
          const pnl = Object.values(totalPNL).reduce((pv,cv)=>pv+cv,0);
          return pnl.toFixed(2);
      }
    
    
      return (
        <>
          {show ? (
            <EditDeployStrategyPop
              name={strategy?.name}
              obj={obj}
              brokers={brokers}
              setEditPlan={setEditPlan}
              editPlan={editPlan}
              show={show}
              handlePopClose={handlePopClose}
            />
          ) : (
            <></>
          )}
          {strategy ? <>
            <div className="row mt-1 border shadow rounded-2 p-3">
              <div className="col-xl-4 col-lg-5">
                <h4 style={{ fontSize: "1.2em" }}>
                  {strategy?.name} ({JSON.parse(strategy.instrument).name})
                </h4>
                &nbsp;
                <b className="text-danger border shadow">{editPlan.broker}</b> <br />
                {strategy.legs.map((l) => (
                  <>
                    <b
                      style={{ fontSize: "0.8em" }}
                      className={l.type == "BUY" ? "text-success" : "text-danger"}
                    >
                      {`${l.ttype}${l.qty * editPlan.qtyMultiply}${l.tm.toUpperCase()}`}
                    </b>{" "}
                    &nbsp;&nbsp;
                  </>
                ))}
              </div>
              <div className="col-xl-2 col-lg-2 d-flex justify-content-center align-items-center">
                {/* PNL :{" "}
                <CurrencyRupee style={{ fontSize: "1em" }} className="text-dark" />{" "}
                {getTotalPNL()} */}
              </div>
              <div className="col-xl-3 col-lg-2 d-flex justify-content-center align-items-center">
                 <button className="btn-sm btn-primary" onClick={startStrategy}>
                    Start
                  </button>
                  &nbsp;
                  <button className="btn-sm btn-danger" onClick={unDepoly}>
                    Stop
                  </button>
              </div>
    
              <div className="col-xl-2 col-lg-1 d-flex justify-content-evenly align-items-center">
               
                    {/* <button onClick={unDepoly} className="btn-sm btn-danger">
                      <FaTrash />
                    </button> */}
                    <EditSharp
                      onClick={edit}
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      title="Edit"/>
                   
                  {showRun?<FaChevronUp onClick={()=>setShowRun(false)}/>:<FaChevronDown onClick={()=>setShowRun(true)}/>}
              </div>
            </div>
            
            <div style={{display: showRun?'block':'none'}}>
            {runningStrategy.map((runStr,index)=>runStr.strategy.type=="Time-Based"?<TimeBasedItem key={`run${index}`} token={token} index={index} 
            strategy={strategy} 
            runStr={runStr} 
            changeStrategyPNL={changeStrategyPNL}
            updateRunningStrategy={updateRunningStrategy} 
            deleteRunningStrategy={deleteRunningStrategy}/>:<IndicatorBasedItem key={`run${index}`} token={token} index={index} 
            strategy={strategy} 
            runStr={runStr} 
            changeStrategyPNL={changeStrategyPNL}
            updateRunningStrategy={updateRunningStrategy} 
            deleteRunningStrategy={deleteRunningStrategy}/>)}
            </div>
          </> : (
            <></>
          )}
        </>
      );
    
     
    };
    

function EditDeployStrategyPop({
      name,
      obj,
      brokers,
      show,
      handlePopClose,
      setEditPlan,
      editPlan,
    }) {
      let popObj = {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        height: "fit-content",
        width: "max-content",
        maxWidth: "70%",
        minWidth: "400px",
        backgroundColor: "white",
        borderRadius: "10px",
      };
      const token = useSelector((store) => store.user.value.token);
      const [isAccept, setIsAccept] = useState(false);
    
      const qtyMultiplyRef = useRef();
      const brokerRef = useRef();
      const squareOffRef = useRef();
    
      const updateItem = () => 
      {
        const qtyMultiply = qtyMultiplyRef.current.value * 1;
        const broker = brokerRef.current.value;
        const squareoff = squareOffRef.current.value;
    
        const newItem = {
          qtyMultiply,
          broker: broker.length == 0 ? null : broker,
          squareoff,
        };
        
        setEditPlan(newItem);
        handlePopClose()
      };
    
      return (
        <div
          className={`modal fade ${show ? "show d-block" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div style={popObj} className="p-4">
            <div className="modal-header">
              <h5 className="modal-title">{name}</h5>
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true" onClick={handlePopClose}>
                  &times;
                </span>
              </button>
            </div>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="quantity">Quantity Multiplied</label>
                  <input
                    type="number"
                    ref={qtyMultiplyRef}
                    className="form-control"
                    id="quantity"
                    defaultValue={editPlan.qtyMultiply}
                    min="1"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="brokerName">Broker Name</label>
                  <select
                    className="form-control"
                    ref={brokerRef}
                    id="brokerName"
                    defaultValue={editPlan.broker}
                  >
                    <option value="">Select Broker</option>
                    {brokers.map((b) => (
                      <option disabled={!b.isdeployon}>{b.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="maxProfit">Max Profit (Optional)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="maxProfit"
                    defaultValue="0"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="squareOffTime">Square Off Time</label>
                  <input
                    type="time"
                    className="form-control"
                    ref={squareOffRef}
                    id="squareOffTime"
                    defaultValue={editPlan.squareoff}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="maxLoss">Max Loss (Optional)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="maxLoss"
                    defaultValue="0"
                  />
                </div>
                {/* <div className="form-group col-md-6">
                  <label>Deployment Type</label>
                  <div className="d-flex">
                    <button type="button" className="btn btn-outline-primary mr-2">
                      Live
                    </button>
                    <button type="button" className="btn btn-primary">
                      Forward Test
                    </button>
                  </div>
                </div> */}
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="terms"
                    checked={isAccept}
                    onChange={(e) => setIsAccept(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="terms">
                    I accept all the <a href="/"> &nbsp;terms & conditions</a>
                  </label>
                </div>
              </div>
            </form>
            <hr />
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handlePopClose}
            >
              Close
            </button>
            &nbsp;&nbsp;
            {isAccept ? (
              <button
                type="button"
                className="btn btn-success"
                onClick={updateItem}
              >
                Update
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }





    export default DeployedItem;