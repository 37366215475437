import { Link } from "react-router-dom";
import "./footer.css";
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa";

export default function Footer() {
  return (
    <>
      <footer className="bg-light">
        <div className="container pt-3 border-bottom">
          <div className="row justify-content-between text-center text-md-left">
            {/* Logo Section */}
            <div className="col-md-3 col-sm-12 mb-3">
              <img
                src={"/images/logo.svg"}
                alt="Logo"
                className="foot_logo img-fluid h-100%"
              />
            </div>

            {/* Footer Links Section */}
            <div className="col-md-9 col-sm-12">
              <div className="row">
                {/* Other Links */}
                <div className="col-md-3 col-sm-6 mb-3">
                  <h5 className="font-weight-bold text-uppercase">
                    Other Links
                  </h5>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        to={"/about"}
                        className="text-danger"
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/policy"}
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                      >
                        Privacy / Policies
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/tnc"}
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                      >
                        Terms / Conditions
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* Social Links */}
                <div className="col-md-3 col-sm-6 mb-3">
                  <h5 className="font-weight-bold text-uppercase">CONNECT</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=61551056345056&mibextid=ZbWKwL"
                        target="_blank"
                        className="d-flex align-items-center gap-2"
                      >
                        <FaFacebook className="mr-2" /> Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtube.com/@theprofitology?si=w0YA7qZOc_m-y0a2"
                        target="_blank"
                        className="d-flex align-items-center gap-2"
                      >
                        <FaYoutube className="mr-2" /> YouTube
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/profitologywebeliveinprofit?igsh=emxlaTl1ZjVlYnMz"
                        target="_blank"
                        className="d-flex align-items-center gap-2"
                      >
                        <FaInstagram className="mr-2" /> Instagram
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wa.me/919664143801"
                        target="_blank"
                        className="d-flex align-items-center gap-2"
                      >
                        <FaWhatsapp className="mr-2" /> WhatsApp
                      </a>
                    </li>
                  </ul>
                </div>

                {/* Contact Section */}
                <div className="col-md-4 col-sm-12 mb-3">
                  <h5 className="font-weight-bold text-uppercase">CONTACT</h5>
                  <p className="mb-1">
                    <strong>Phone:</strong> <br />
                    <a href="tel:+919664143801">+91 9664143801</a>,{" "}
                    <a href="tel:+917665533911">+91 7665533911</a>
                  </p>
                  <p className="mb-0">
                    <strong>Registered Office:</strong> <br />
                    Udaipur, Rajasthan
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
