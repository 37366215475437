import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import apiCall, { URLS } from "../../webservice/ApiCallService";

export default function ClientTracker() 
{
  const location = useLocation();
  const { name, id } = location.state;
  const loginUserToken = useSelector((store) => store.user.value.token);

  const [orders,setOrders] = useState([]);

  useEffect(()=>{
    loadOrders();
  },[])

  const loadOrders = async ()=>
  {
    const url = URLS.LIST_ALL_ORDERS_CLIENTID + id; 
    const result = await apiCall.getCallWithToken(url,loginUserToken);
    if(result.status){
      setOrders(result.data);
    }
  }

  const parseDate = (datestr)=>
  {
    const date = new Date(datestr);

    // Extract individual components
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // Months are 0-indexed
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    return `${day}/${month}/${year}`;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
            <div className="col-xl-4"> <h3 id="HeadText">CLIENT TRACKER</h3></div>
            <div className="col-xl-6 text-center text-success"><h4><b>{name}</b></h4></div>
        </div>       
        <hr />
        <div className="row mt-3">
          <h2>Recent Orders</h2>

          <table className="table">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Strategy</th>
                <th>Order Date</th>                
                <th>Strike</th>
                <th>Exchange</th>
                <th>Order Status</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((ord,index)=><tr key={`od${index}`}>
                <td>{index+1}</td>
                <td>
                  <b>{ord.strategy.type} , {JSON.parse(ord.strategy.instrument).name}</b> <br/>
                  {ord.ordertype} , {ord.qty}
                </td>
                <td>{parseDate(ord.order_date)}</td>
                <td>{ord.strike}</td>
                <td>{ord.tradingSymbol}</td>
                <td>{ord.order_status}</td>
              </tr>)}
            </tbody>
          </table>
        </div>
      

      </div>
    </>
  );
}
