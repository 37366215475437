import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useEffect, useRef, useState } from "react";
import {
  FaBan,
  FaClosedCaptioning,
  FaPlus,
  FaSave,
  FaTrash,
  FaUserAltSlash,
  FaUserCheck,
} from "react-icons/fa";
import toast from "react-hot-toast";
import TimeBased from "./TimeBased";
import IndicatorBased from "./IndicatorBased";
import InstrumentPop from "./instrument/InstumentPop";
import { useNavigate, useParams } from "react-router-dom";
export default function Strategies() 
{
  const sid = useParams().id;
  var [updateObj,setUpdateObj] = useState(undefined)
  const navigate = useNavigate();

  const [showPop, setShowPop] = useState(false);
  const handleOpenPop = () => setShowPop(true);
  const handleClosePop = () => setShowPop(false);

  const loginUserToken = useSelector((store) => store.user.value.token);
  const [indicator,setIndicator] = useState(undefined)

  const startTimeRef = useRef();
  const squareOffRef = useRef();
  const exitWhenProfitRef = useRef();
  const exitWhenLossRef = useRef();
  const noTradeAfterRef = useRef();
  const trailProfitReachRef = useRef();
  const trailLockProfitRef = useRef();
  const trailIncreaseProfitRef = useRef();
  const trailProfitByRef = useRef();
  const strategyNameRef = useRef();

  const strategyTypeLBLbox = useRef();
  const [strategyType, setStrategyType] = useState(true);

  const orderTypeLBLbox = useRef();
  const [orderType, setOrderType] = useState(true);

  const [instrument, setInstrument] = useState(undefined);

  const [legs, setLegs] = useState([]);
  const [isTrailing, setIsTrailing] = useState(false);

  const [weekdays, setWeekDays] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
  });

  useEffect(()=>{
    if(isTrailing && updateObj!=undefined){
      trailProfitReachRef.current.value = updateObj.trail_profit_reach;
      trailLockProfitRef.current.value = updateObj.trail_lock_profit;
      trailIncreaseProfitRef.current.value = updateObj.trail_increase_profit;
      trailProfitByRef.current.value = updateObj.trail_profitby;
    }
  },[isTrailing])
  useEffect(() => {
    // Load Strategy Record when update
    if (sid != undefined) {
      loadStrategy();
    }
  }, []);
  const loadStrategy = () => {
    const url = URLS.GET_ADMIN_STRATEGY + sid;
    apiCall.getCallWithToken(url, loginUserToken).then((result) => {
      if (result.status) {
        const rec = result.data;
        setUpdateObj(rec);
        setStrategyType(rec.type == "Time-Based");
        setOrderType(rec.type == "Delivery");
        startTimeRef.current.value = rec.start_time;
        squareOffRef.current.value = rec.squareoff_time;
        setWeekDays(JSON.parse(rec.week_days));
        setInstrument(JSON.parse(rec.instrument));
        setLegs(rec.legs.map(r=>{
          return {...r,sl:JSON.parse(r.sl),tp:JSON.parse(r.tp)}
        }));
        exitWhenProfitRef.current.value = rec.exit_when_all_profit;
        exitWhenLossRef.current.value = rec.exit_when_all_loss;
        noTradeAfterRef.current.value = rec.no_trade_after;
        setIsTrailing(rec.is_trailing);
        if (rec.is_trailing) {
         
        }
        strategyNameRef.current.value = rec.name;
      } else {
        toast.error(result.msg);
        navigate("/admin/viewstrategy");
      }
    });
  };

  const toggle = (event, setFun, lbl) => {
    if (event.target.checked) {
      setFun(true);
      lbl.current.style.backgroundColor = "green";
      lbl.current.parentElement.style.color = "green";
    } else {
      setFun(false);
      lbl.current.style.backgroundColor = "#a32626";
      lbl.current.parentElement.style.color = "#a32626";
    }
    setLegs([]);
  };

  const handleWeekDays = (k) => {
    const days = { ...weekdays };
    days[k] = !days[k];
    setWeekDays(days);
  };

  const copyleg = (lob) => {
    const ob = { ...lob };
    delete ob.id;
    setLegs([...legs, ob]);
  };
  const removeleg = (lob) => {
    setLegs(legs.filter((ob, idx) => ob!=lob));
  };
  const addleg = () => {
    if (instrument == undefined) toast.error("Add Instrument First !");
    else {
      const ob = {
        type: "BUY", // SELL
        qty: instrument?.lot, // Depends on Instruments
        ttype: "Call", // PUT
        expiry: "", // Monthly
        tm: "ATM", // ITM , OTM
        sl: {
          type: "Point", // Percentage
          value: 0,
        },
        tp: {
          type: "Point", // Percentage
          value: 0,
        },
      };
      setLegs([...legs, ob]);
    }
  };

  const updateLeg = (ele, index) => 
  {
    const eleName = ele.getAttribute("data-name");
    const value = ele.value;
    console.log(eleName , value)
    var leg = legs[index];

    switch (eleName) {
      case "type":
        leg = { ...leg, type: value };
        break;
      case "qty":
        leg = { ...leg, qty: value };
        break;
      case "ttype":
        leg = { ...leg, ttype: value };
        break;
      case "expiry":
        leg = { ...leg, expiry: value };
        break;
      case "tm":
        leg = { ...leg, tm: value };
        break;
      case "sl-type":
        leg = { ...leg, sl: { ...leg.sl, type: value } };
        break;
      case "sl-value":
        leg = { ...leg, sl: { ...leg.sl, value: value } };
        break;
      case "tp-type":
        leg = { ...leg, tp: { ...leg.tp, type: value } };
        break;
      case "tp-value":
        leg = { ...leg, tp: { ...leg.tp, value: value } };
        break;
    }
    setLegs(legs.map((ob, idx) => (idx == index ? leg : ob)));
  };

  const save = (e) => {
    e.preventDefault();

    const data = {
      name: strategyNameRef.current.value,
      type: strategyType ? "Time-Based" : "Indicator-Based",
      order_type: orderType ? "Delivery" : "Intraday",
      start_time: startTimeRef.current.value,
      squareoff_time: squareOffRef.current.value,
      week_days: weekdays,
      instrument: instrument,
      legs: strategyType ? legs : [],
      indicator : strategyType ? null : indicator,
      exit_when_all_profit: exitWhenProfitRef.current.value==''?0:exitWhenProfitRef.current.value,
      exit_when_all_loss: exitWhenLossRef.current.value==''?0:exitWhenLossRef.current.value,
      no_trade_after: noTradeAfterRef.current.value, 
      is_trailing: isTrailing,
      trail_profit_reach: isTrailing ? trailProfitReachRef.current.value : 0,
      trail_increase_profit: isTrailing
        ? trailIncreaseProfitRef.current.value
        : 0,
      trail_profitby: isTrailing ? trailProfitByRef.current.value : 0,
      trail_lock_profit: isTrailing ? trailLockProfitRef.current.value : 0,
    };

    if (sid == undefined || sid == null) {
      apiCall
        .postCallWithToken(URLS.SAVE_STRATEGY, loginUserToken, data)
        .then((result) => {
          if (result.status) {
            toast.success("Strategy Saved !");
            e.target.reset();
            setLegs([]);
            setInstrument(undefined);
            setWeekDays({
              Mon: false,
              Tue: false,
              Wed: false,
              Thu: false,
              Fri: false,
            });
          } else {
            toast.error("Strategy Not Saved !");
          }
        });
    }else{
      const url = URLS.UPDATE_STRATEGY+sid
      apiCall.putCallWithToken(url,loginUserToken,data)
      .then(result=>{
        if(result.status)
        {
          toast.success(result.msg);
          navigate("/admin/viewstrategy");
        }else{
          toast.error(result.msg);
        }
      })
    }
  };

  return (
    <>
      <InstrumentPop
        show={showPop}
        handleClose={handleClosePop}
        type={strategyType}
        setInstrument={setInstrument}
      />
      <div className="container-fluid pt-3">
        <h3 id="HeadText">Strategies</h3>
        <form onSubmit={save}>
          <div className="row mt-4 justify-content-start bg-light shadow p-4 rounded">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4">
              <h5 className="text-primary txt-ttl">Strategy Type</h5>
              <div className="d-flex align-items-center justify-content-start">
                <div className="toggleswitchUser">
                  <input
                    type="checkbox"
                    className="checkbox"
                    id="strategyTypeCheck"
                    onChange={(e) =>
                      toggle(e, setStrategyType, strategyTypeLBLbox)
                    }
                    checked={strategyType}
                  />
                  <label
                    htmlFor="strategyTypeCheck"
                    ref={strategyTypeLBLbox}
                    className="checkbox-label"
                  >
                    <span className="ball"></span>
                  </label>
                  <b className=" txt-ttl">
                    {strategyType ? "Time Based" : "Indicator Based"}
                  </b>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12 mb-4">
              <h5 className="text-primary txt-ttl">Order Type</h5>
              <div className="d-flex align-items-center justify-content-start">
                <div className="toggleswitchUser">
                  <input
                    type="checkbox"
                    className="checkbox"
                    id="orderTypeCheck"
                    onChange={(e) => toggle(e, setOrderType, orderTypeLBLbox)}
                    checked={orderType}
                  />
                  <label
                    htmlFor="orderTypeCheck"
                    ref={orderTypeLBLbox}
                    className="checkbox-label"
                  >
                    <span className="ball"></span>
                  </label>
                  <b className=" txt-ttl">
                    {orderType ? "Delivery" : "Intraday"}
                  </b>
                </div>
              </div>
            </div>

            <div className="col-xl-7 col-lg-8 col-md-12 col-sm-12 align-content-center">
              <div className="row justify-content-start align-items-center">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <b>Start Time</b>
                  <input
                    type="time"
                    ref={startTimeRef}
                    className="form-control"
                    placeholder="Start Time"
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                  <b>Square Off</b>
                  <input
                    type="time"
                    ref={squareOffRef}
                    className="form-control"
                  />
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 p-1">
                  <div className="btn-group" role="group">
                    {Object.keys(weekdays).map((k) => (
                      <button
                        type='button'
                        key={k}
                        onClick={() => handleWeekDays(k)}
                        className={` m-1 rounded btn btn-sm ${
                          weekdays[k]
                            ? "btn-secondary"
                            : "btn-outline-secondary"
                        }`}
                      >
                        {k}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            {instrument == undefined ? (
              <div className="col-xl-2 col-lg-2">
                <h5>Add Instruments</h5>
                <button type='button' className="btn btn-success" onClick={handleOpenPop}>
                  <FaPlus />
                  &nbsp;&nbsp; Add
                </button>
              </div>
            ) : (
              <div className="col-xl-2 col-lg-2 justify-content-around align-items-center mt-3 p-3 bg-light shadow-sm rounded">
                <FaTrash
                  onClick={() => {
                    setInstrument(undefined);
                    //setLegs([]);
                  }}
                  className="text-danger mb-3 action-icon"
                />
                <h5>{instrument.name}</h5>
                <b className="text-primary"> LOT : {instrument.lot} </b>
              </div>
            )}
          </div>
          <hr />
          {strategyType ? (
            <TimeBased
              copyleg={copyleg}
              removeleg={removeleg}
              addleg={addleg}
              legs={legs}
              updateLeg={updateLeg}
              instrument={instrument}
            />
          ) : (
            <IndicatorBased  indicator={indicator} setIndicator={setIndicator}/>
          )}
          <hr />

          <div className="container mt-4">
            <h2 className="mb-4 setting-ttl">Risk Settings</h2>
            <div className="row g-3">
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="input-group">
                  <b className="input-group-text">Exit When Over All Profit</b>
                  <input
                    type="number"
                    ref={exitWhenProfitRef}
                    className="form-control"
                    placeholder=""
                  />
                  {/* <span className="input-group-text">%</span> */}
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="input-group">
                  <b className="input-group-text">Exit When Over All Loss</b>
                  <input
                    type="number"
                    ref={exitWhenLossRef}
                    className="form-control"
                    placeholder=""
                  />
                  {/* <span className="input-group-text">%</span> */}
                </div>
              </div>
              <div className="col-xl-3 col-lg-12 col-md-12">
                <div className="input-group">
                  <b className="input-group-text">No Trade After</b>
                  <input
                    type="time"
                    ref={noTradeAfterRef}
                    id="noTradeTime"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-4 p-4 bg-light shadow-sm rounded">
            <h2 className="mb-4 setting-ttl">Profit Trailing</h2>
            <div className="row g-4 align-items-center">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <label className="form-check-label">
                  <input
                    type="radio"
                    onChange={() => setIsTrailing(false)}
                    name="trailing"
                    checked={!isTrailing}
                    className="form-check-input"
                  />
                  &nbsp; No Trailing
                </label>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <label className="form-check-label">
                  <input
                    type="radio"
                    onChange={() => setIsTrailing(true)}
                    name="trailing"
                    checked={isTrailing}
                    className="form-check-input"
                    required
                  />
                  &nbsp; Lock and Trail
                </label>
              </div>

              {isTrailing && (
                <div className="col-xl-12 col-lg-12">
                  <div className="row g-3">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <input
                        className="form-control"
                        ref={trailProfitReachRef}
                        type="number"
                        placeholder="If Profit Reaches"
                        required
                      />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <input
                        className="form-control"
                        ref={trailLockProfitRef}
                        type="number"
                        placeholder="Lock Profit"
                        required
                      />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <input
                        className="form-control"
                        ref={trailIncreaseProfitRef}
                        type="number"
                        placeholder="Every Increase In Profit By"
                        required
                      />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <input
                        className="form-control"
                        ref={trailProfitByRef}
                        type="number"
                        placeholder="Trail Profit By"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <hr />
          <div className="row mt-3">
            <div className="col-xl-4 col-lg-4">
              <input
                type="text"
                className="form-control"
                ref={strategyNameRef}
                placeholder="Strategy Name"
                required
              />
            </div>
            <div className="col-xl-2 col-lg-2">
              <button className="btn btn-primary">
                <FaSave /> &nbsp; Save Strategy
              </button>
            </div>
          </div>
        </form>
        <div style={{ height: 400, width: "100%" }}>
          {/* <DataGrid rows={mlist} columns={columns} pageSize={5} /> */}
        </div>
      </div>
    </>
  );
}
