import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import toast from "react-hot-toast";
import { CurrencyRupee } from "@mui/icons-material";

export default function IndicatorBasedItem({token,runStr,deleteRunningStrategy})
{
    const clientinfo = useSelector(store=>store.clientinfo.value.brokers);

     const orderIntervalRef = useRef(undefined);
        
              const [orders,setOrders] = useState([]);
              const fetchPriceRef = useRef(undefined);
        
              useEffect(()=>
              {
                  if(runStr.isordered)
                  {
                    fetchPriceRef.current = setInterval(()=>{
                      loadCurrentPrices();
                    },3000) 
                  }
        
                  return ()=>{
                    clearInterval(fetchPriceRef.current)
                  }
              },[runStr]);
        
              const loadCurrentPrices = async()=>
              {
                 const priceResult = await apiCall.postCallWithToken(URLS.FETCH_CURRENT_PRICES,token,{runningStrategyID : runStr._id});
                 if(priceResult.status){
                    setOrders(priceResult.data);
                    //console.log(">> " , runStr._id , priceResult.data.length)
                 }
              }
              const showStopConfirmBox = () => {
                toast(
                  (t) => (
                    <div>
                      <p>Are you sure to stop / delete ?</p>
                      <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                        <button
                          onClick={() => {
                            toast.dismiss(t.id); // Close the toast
                            stopStrategy(); // Handle confirmation logic
                          }}
                          style={{
                            background: 'green',
                            color: 'white',
                            border: 'none',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                          }}
                        >
                          Confirm
                        </button>
                        <button
                          onClick={() => toast.dismiss(t.id)} // Close the toast
                          style={{
                            background: 'red',
                            color: 'white',
                            border: 'none',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ),
                  { duration: Infinity } // Keep the toast open until the user interacts
                );
              };
        
                const stopStrategy = async () => 
                {
                  const did = runStr._id;
                  const URL = URLS.STOP_STRATEGY + did;
                  const result = await apiCall.postCallWithToken(URL, token);
                  if (result.status) {
                    toast.success(result.msg);
                    clearInterval(orderIntervalRef.current);
                    deleteRunningStrategy(did);
                  } else {
                    toast.error(result.msg);
                  }
                };
        
              const getTotalPNL = ()=>
              {
                let pnl = 0;
                for(let leg of runStr.legs){
                  const legpnl = getPNL(leg,runStr.qtyMultiply,orders);
                  //console.log(legpnl , typeof legpnl)
                  pnl += legpnl;
                }
                //changeStrategyPNL(runStr._id,pnl);
                return pnl.toFixed(2);
              }
              const getPNL = (sl,qtyMultiply,orders)=>
              {
                const order = orders.find(ord=>ord.legid===sl.legid);
                if(order==null || order==undefined)
                  return 0.0;
                else
                {
                  let pnl = 0.0;
                  const tradeQty = sl.qty * qtyMultiply;
                  if(sl.type==="BUY")
                  {
                    pnl = (order.price - order.tradePremium)*tradeQty;
                  }
                  else
                  {
                    pnl = (order.tradePremium - order.price)*tradeQty;
                  }
                  return isNaN(pnl)?0:pnl;
                }
              }
        
              const checkOrderStatus = (sl,qtyMultiply,orders)=>
              {
                const order = orders.find(ord=>ord.legid===sl.legid);
                if(order==null || order==undefined)
                  return {msg : "PNL : 0.0" , pnl:0};
                else
                {
                  if(order.status==false)
                    return {msg : order.order_status , pnl : 0};
                  else
                  {
                      const pnl = getPNL(sl,qtyMultiply,orders).toFixed(2);
                      return { msg : "PNL: "+pnl , pnl}
                  }
                }
              };
        
              const showSquareOffConfirmBox = () => {
                toast(
                  (t) => (
                    <div>
                      <p>Are you sure to SquareOff ?</p>
                      <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                        <button
                          onClick={() => {
                            toast.dismiss(t.id); // Close the toast
                            squareOff(); // Handle confirmation logic
                          }}
                          style={{
                            background: 'green',
                            color: 'white',
                            border: 'none',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                          }}
                        >
                          Confirm
                        </button>
                        <button
                          onClick={() => toast.dismiss(t.id)} // Close the toast
                          style={{
                            background: 'red',
                            color: 'white',
                            border: 'none',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ),
                  { duration: Infinity } // Keep the toast open until the user interacts
                );
              };
        
              const squareOff = async ()=>
              {
                const {_id,broker} = runStr;
                const brokerObj = clientinfo.find(cl=>cl.name===broker);
                //console.log(_id,broker,brokerObj)
                if(brokerObj.isterminalon)
                {
                  await apiCall.postCallWithToken(URLS.SQUARE_OFF,token,{runningStrategyID:_id,clientBrokerID:brokerObj.id});
                }else{
                  toast.error(`${broker} Terminal Off.`)
                }
              }
        

    return <div  className="row border shadow rounded-2 p-3 alert-primary">
    <div className="col-xl-2 col-lg-2 d-flex justify-content-center align-items-center">
      {runStr.isordered?<div>
        <b className="text-danger">{orders.every(od=>od.order_status=="squareoff")?"SquareOff":"Running"}</b>
        <br/>

        {orders.length>0 && orders.some(ord=>ord.status) &&  orders.some(ord=>ord.position_status)?<button className="btn-sm btn-info" onClick={showSquareOffConfirmBox}>SquareOff</button>:""}

        {orders.length>0 && orders.every(od=>od.order_status=="squareoff")?<button className="btn-sm btn-danger" onClick={showStopConfirmBox}>Delete</button>:""} 
      </div>:<div>
        <b className="text-danger">{runStr.action}</b>
        <br/>
        <button className="btn-sm btn-danger" onClick={showStopConfirmBox}>Stop</button>
        <br/><br/>
        {/* <b className="text-danger">{convertMilliseconds(orderDiff)}</b> */}
      </div>}
     
    </div>
    <div className="col-xl-2 col-lg-2 d-flex justify-content-center align-items-center">
      <CurrencyRupee style={{ fontSize: "1.5em" }} className="text-dark" />
      <b>{getTotalPNL()}</b>
    </div> 
    {runStr.legs.map((sl,index)=>
    {
      const pnl = checkOrderStatus(sl,runStr.qtyMultiply,orders);
      return <div className="col-xl-2 col-lg-2 d-flex justify-content-center align-items-center">
          <div className={pnl.pnl<=0?'alert-danger p-2 rounded':'alert-success p-2 rounded'}>
          {sl.ttype}:{sl.tm.toUpperCase()}:{sl.type}
          <br/>
          {pnl.msg}
          </div>
      </div>
    })}
</div>
}