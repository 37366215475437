import { useState } from "react"
import { FaTrash } from "react-icons/fa"

const indicators = [
    {id: "ind1" , name : "EMA CrossOver"},
 //   {id: "ind2" , name : "Regression"},
  //  {id: "ind3" , name : "EMA Sigma"}
]

export default function IndicatorBased({indicator,setIndicator})
{
   
    return <>
        <h2>Indicator Based Settings</h2>

        <div className="row mt-2">

                {indicator!=undefined?<div className="col-xl-2 col-lg-2 justify-content-around align-items-center p-3 bg-light shadow-sm rounded">
                            <FaTrash onClick={()=>setIndicator(undefined)} className="text-danger mb-3 action-icon"/>
                            <h5>{indicator.name}</h5>
                            <b className="text-primary"></b>
                </div>:""}   

            <h4 className="mt-3">Choose Indicator</h4>
            {indicators.map(ind=><div onClick={()=>setIndicator(ind)} className="col-xl-2 col-lg-2 rounded selbox mr-3">
                            <h5>{ind.name}</h5>
                            <b className="text-primary"></b>
                          </div>)}
        </div>
    </>
}